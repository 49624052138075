import React from 'react';
import Layout from '../components/layout';

function LayoutContainer(props) {
  console.log('%cHi! 🤗 How are you? ', "color: #F2AA4C;font-size:30px;");
  return (
    <Layout
      {...props}
    />
  );
}

export default LayoutContainer;
