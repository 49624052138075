import React from 'react';
import Footer from './footer';
import SEO from './seo';
// import PageTransition from 'gatsby-plugin-page-transitions';
import '../styles/app.scss';

const Layout = ({
  children,
}) => (
  <div className={`wrapper`}>
    <SEO title={'Håvard Bergersen - Experienced full-stack developer and tech lead.'}  />
    {/* <Header
      location={location}
    /> */}
    <main>{children}</main>
    <Footer />
  </div>
  // </PageTransition>
);

export default Layout;
