import React from "react";

const now = new Date();
const year = now.getFullYear();

function UnCryptMailto(s) {
  var n = 0;
  var r = "";
  for (var i = 0; i < s.length; i++) {
    n = s.charCodeAt(i);
    if (n >= 8364) {
      n = 128;
    }
    r += String.fromCharCode(n - 1);
  }
  return r;
}

function linkTo_UnCryptMailto(s) {
  if (!window) {
    return null;
  }
  window.location.href = UnCryptMailto(s);
}

const Footer = () => (
  <>
    <section className="contact">
      <div className="contact__content">
        <div className="contact__text">
          {/* <p>Want to work with me?</p> */}
          <p>
            Email:{" "}
            <a
              onClick={e =>
                linkTo_UnCryptMailto("nbjmup;ifjAibwbsecfshfstfo/dpn")
              }
            >
              hei [at] havardbergersen [dot] com
            </a>
            <br />
            Phone: +47 480 77 170
          </p>
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.linkedin.com/in/havardbergersen"
            >
              See my LinkedIn-page for full profile.
            </a>
          </p>
        </div>
      </div>
    </section>
    <footer className="footer">
      {year} &copy; Håvard Bergersen | Built with ♥️ and{" "}
      <a
        className="footer__link"
        href="https://sanity.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        Sanity
      </a>
    </footer>
  </>
);

export default Footer;
